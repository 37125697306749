<template>
  <v-container fluid>
    <v-card class="mb-3" v-if="filter">
      <v-toolbar dense flat>
        <v-toolbar-title>Vadības panelis</v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <AlertResponse :response="alert"/>
    </v-card>
    <v-tabs>
      <v-tab @click="fetchData">Kampaņas</v-tab>
      <v-tab @click="fetchSpreadsheets">Rezultāti</v-tab>
      <v-tab-item>
        <v-card :loading="loading">
          <v-divider/>
          <v-card-text class="py-0 px-5">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="search" prepend-icon="fa-search" label="Meklēt" single-line hide-details clearable/>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="month"
                  :items="months"
                  item-text="name"
                  item-value="value"
                  label="Mēneši"
                  @change="filterMonthSearch"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  :loading="loading"
                  v-model="week"
                  :items="weeks"
                  item-text="full_date"
                  item-value="week"
                  label="Weeks"
                  @change="filterWeekSearch"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="activeCompanies" label="Aktīvie SIA sk.," readonly></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-data-table
            :headers="headersCompanies"
            :items="companies"
            :search="search"
            single-expand
            :expanded.sync="expanded"
            item-key="company_id"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :item-class="itemRowBackground"
            show-expand
            disable-pagination
            hide-default-footer
            height="790"
            fixed-header
          >
            <template v-slot:top>
              <v-dialog
                v-model="advertisementDialog"
                max-width="900px"
              >
                <v-card>
                  <v-card-title>Rediģēt reklāmu</v-card-title>
                  <v-card-text>
                    <v-form>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <v-text-field label="Nosaukums" v-model="editedAdv.name" outlined autofocus required/>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-text-field label="Type" v-model="editedAdv.type" outlined required />
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-text-field label="Budžets" v-model="editedAdv.budget" outlined required/>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-text-field label="Ad set sk." v-model="editedAdv.ad_set" outlined/>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between">
                          <v-switch v-model="editedAdv.target_type" value="L" label="Lead"></v-switch>
                          <v-switch v-model="editedAdv.target_type" value="T" label="Target"></v-switch>
                          <v-switch v-model="editedAdv.target_type" value="R" label="Reach"></v-switch>
                          <v-switch v-model="editedAdv.target_type" value="LL" label="Lookalike"></v-switch>
                          <v-switch v-model="editedAdv.target_type" value="PO" label="Post"></v-switch>
                          <v-switch v-model="editedAdv.target_type" value="P" label="Product set"></v-switch>
                          <v-switch v-model="editedAdv.target_type" value="RM" label="Remarketing"></v-switch>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-dialog
                            ref="startDateDialog"
                            v-model="startDateDialog"
                            :return-value.sync="editedAdv.date_start"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedAdv.date_start"
                                label="Sākuma datums"
                                prepend-icon="fa-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="editedAdv.date_start" scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="startDateDialog = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.startDateDialog.save(editedAdv.date_start)">OK</v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-dialog
                            ref="endDateDialog"
                            v-model="endDateDialog"
                            :return-value.sync="editedAdv.date_end"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedAdv.date_end"
                                label="Datuma beigas"
                                prepend-icon="fa-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="editedAdv.date_end" scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="endDateDialog = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.endDateDialog.save(editedAdv.date_end)">OK</v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeAdvertisement">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveAdvertisement">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!-- DIALOG -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="px-0">
                <v-data-table
                  v-if="item.advertisements"
                  :headers="headersAdvertisements"
                  :items="item.advertisements"
                  item-key="advertisement_id"
                  disable-pagination
                  hide-default-footer
                  class="blue-grey lighten-5"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-menu bottom :close-on-click="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>fa-ellipsis-v</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item :to="{name: 'advertisementShow', params: {id: item.advertisement_id}}">
                          <v-list-item-title>Skatīt reklāmu</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{name: 'taskCreate', params: {advertisement_id: item.advertisement_id}}">
                          <v-list-item-title>Pievienot uzdevumu</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="duplicateAdvertisement(item.advertisement_id)">
                          <v-list-item-title>Kopēt reklāmu</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteAdvertisement(item.advertisement_id)">
                          <v-list-item-title>Dzēst reklāmu</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="editAdvertisement(item.advertisement_id)">
                          <v-list-item-title>Rediģēt reklāmu</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-slot:item.balance="{ item }">
              <v-chip v-if="item.balance" :color="level(item.balance)" text-color="white">{{ item.balance }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu bottom :close-on-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item :to="{name: 'advertisementCreate', params: {id: item.company_id}}">
                    <v-list-item-title>Pievienot reklāmu</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="disableCompany(item.company_id)">
                    <v-list-item-title>Izslēgt uzņēmumu</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="downloadAdvertisements(item.company_id)">
                    <v-list-item-title>Lejuplāde</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card :loading="loading">
          <v-divider/>
          <v-card-text class="py-0 px-5">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="search" prepend-icon="fa-search" label="Meklēt" single-line hide-details clearable/>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="month"
                  :items="months"
                  item-text="name"
                  item-value="value"
                  label="Mēneši"
                  @change="filterMonthSearchSpreadsheet"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-data-table
            :headers="headersCompanies2"
            :items="companies2"
            :search="search"
            single-expand
            :expanded.sync="expanded2"
            item-key="id"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :item-class="itemRowBackground"
            show-expand
            disable-pagination
            hide-default-footer
            height="790"
            fixed-header
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="px-0">
                <v-data-table
                  :headers="headersAdvertisements2"
                  :items="item.advertisements"
                  item-key="advertisement_id"
                  disable-pagination
                  hide-default-footer
                  class="blue-grey lighten-5"
                />
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu bottom :close-on-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item :to="{name: 'companyShow', params: {id: item.id}}">
                    <v-list-item-title>Skatīt uzņēmumu</v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="{name: 'advertisementCreate', params: {id: item.id}}">
                    <v-list-item-title>Pievienot reklāmu</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="disableCompany(item.id)">
                    <v-list-item-title>Izslēgt uzņēmumu</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="downloadSpreadsheet(item.id)">
                    <v-list-item-title>Lejuplāde</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import axios from '@/axios'
import AlertResponse from '@/components/AlertResponse'

export default {
  name: 'Dashboard',
  components: {
    AlertResponse
  },
  data: () => ({
    alert: null,
    filter: true,
    loading: true,
    search: '',
    sortBy: 'adv_count',
    sortDesc: true,
    months: [
      {
        name: 'Janvāris',
        value: 1
      },
      {
        name: 'Februāris',
        value: 2
      },
      {
        name: 'Marts',
        value: 3
      },
      {
        name: 'Aprīlis',
        value: 4
      },
      {
        name: 'Maijs',
        value: 5
      },
      {
        name: 'Jūnijs',
        value: 6
      },
      {
        name: 'Jūlijs',
        value: 7
      },
      {
        name: 'Augusts',
        value: 8
      },
      {
        name: 'Septembris',
        value: 9
      },
      {
        name: 'Oktobris',
        value: 10
      },
      {
        name: 'Novembris',
        value: 11
      },
      {
        name: 'Decembris',
        value: 12
      }
    ],
    month: 4,
    weeks: [],
    week: new Date().getWeekNumber(),
    expanded: [],
    expanded2: [],
    companies: [],
    companies2: [],
    activeCompanies: 0,
    advertisementDialog: false,
    startDateDialog: false,
    endDateDialog: false,
    editedAdv: {
      name: '',
      ad_account: '',
      date_start: '',
      date_end: '',
      type: '',
      target_type: [],
      budget_date: '',
      budget: '',
      ad_set: ''
    }
  }),
  computed: {
    headersCompanies () {
      return [
        {
          text: 'Uzņēmums',
          value: 'company'
        },
        {
          text: 'Ad seti',
          value: 'ad_set'
        },
        {
          text: 'Sk. Reklāma',
          value: 'adv_count'
        },
        {
          text: 'Sākuma datums',
          value: 'date_start'
        },
        {
          text: 'Datuma beigas',
          value: 'date_end'
        },
        {
          text: 'Lead',
          value: 'is_lead'
        },
        {
          text: 'Reklāma',
          value: 'is_advertisement'
        },
        {
          text: 'Summa',
          value: 'advertisement_budget'
        },
        {
          text: 'Mēneša budž.',
          value: 'month_budget'
        },
        {
          text: 'Budžeta atlk.',
          value: 'balance'
        },
        {
          text: 'Darbības',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersCompanies2 () {
      return [
        {
          text: 'Uzņēmums',
          value: 'company'
        },
        {
          text: 'Amount Spent',
          value: 'amount'
        },
        {
          text: 'Reach',
          value: 'reach'
        },
        {
          text: 'Impressions',
          value: 'impressions'
        },
        {
          text: 'Frequency',
          value: 'frequency'
        },
        {
          text: 'Unique Outbound Clicks',
          value: 'clicks'
        },
        {
          text: 'Unique Outbound CTR',
          value: 'ctr'
        },
        {
          text: 'Cost per Unique Outbound Click',
          value: 'ctr_cost'
        },
        {
          text: 'Leads',
          value: 'leads'
        },
        {
          text: 'Cost per Lead',
          value: 'lead_cost'
        },
        {
          text: 'Darbības',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersAdvertisements () {
      return [
        {
          text: 'Reklāma',
          value: 'advertisement'
        },
        {
          text: 'Menedžeris',
          value: 'user'
        },
        {
          text: 'Sākuma datums',
          value: 'date_start'
        },
        {
          text: 'Datuma beigas',
          value: 'date_end'
        },
        {
          text: 'Reklāma',
          value: 'is_advertisement'
        },
        {
          text: 'Summa',
          value: 'advertisement_budget'
        },
        {
          text: 'Mēneša budž.',
          value: 'month_budget'
        },
        {
          text: 'Budžeta atlk.',
          value: 'balance'
        },
        {
          text: 'Darbības',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersAdvertisements2 () {
      return [
        {
          text: 'Campaign Name',
          value: 'name'
        },
        {
          text: 'Amount Spent',
          value: 'amount'
        },
        {
          text: 'Reach',
          value: 'reach'
        },
        {
          text: 'Impressions',
          value: 'impressions'
        },
        {
          text: 'Frequency',
          value: 'frequency'
        },
        {
          text: 'Unique Outbound Clicks',
          value: 'clicks'
        },
        {
          text: 'Unique Outbound CTR (Click-Through Rate)',
          value: 'ctr'
        },
        {
          text: 'Cost per Unique Outbound Click',
          value: 'ctr_cost'
        },
        {
          text: 'Post Comments',
          value: 'post_comments'
        },
        {
          text: 'Post Saves',
          value: 'post_saves'
        },
        {
          text: 'Post Shares',
          value: 'post_shares'
        },
        {
          text: 'Leads',
          value: 'leads'
        },
        {
          text: 'Cost per Lead',
          value: 'lead_cost'
        }
      ]
    }
  },
  methods: {
    itemRowBackground: function (item) {
      return item.adv_count < 1 ? 'red darken-4 white--text' : 'style-2'
    },
    level (budget) {
      if (budget < 0) {
        return 'red'
      } else {
        return 'green'
      }
    },
    async fetchData () {
      await axios.get('analytics').then(response => {
        this.weeks = response.data.weeks
        this.week = response.data.week
        this.month = response.data.month
        this.companies = response.data.results.companies
        this.activeCompanies = response.data.results.active_companies
      }).catch(error => {
        this.alert = error.data
      })
      await axios.get('analytics/calculating-budget').then(() => {
        this.loading = false
      })
    },
    filterMonthSearch () {
      this.loading = true
      axios.post('analytics', {
        month: this.month
      }).then(response => {
        this.weeks = response.data.weeks
        this.week = response.data.week
        this.month = response.data.month
        this.companies = response.data.results.companies
        this.activeCompanies = response.data.results.active_companies
        this.loading = false
      }).catch(error => {
        this.alert = error.data
      })
    },
    filterWeekSearch () {
      this.loading = true
      axios.post('analytics', {
        month: this.month,
        week: this.week
      }).then(response => {
        this.weeks = response.data.weeks
        this.week = response.data.week
        this.month = response.data.month
        this.companies = response.data.results.companies
        this.activeCompanies = response.data.results.active_companies
        this.loading = false
      }).catch(error => {
        this.alert = error.data
      })
    },
    disableCompany (companyId) {
      const r = confirm('Izslēgt uzņēmumu?')
      if (r === true) {
        axios.post('companies/' + companyId + '/updateStatus', { status: 0 }).then(() => {
          this.fetchData()
        })
      }
    },
    deleteAdvertisement (advertisementId) {
      const r = confirm('Dzēst reklāmu?')
      if (r === true) {
        this.loading = true
        axios.delete('advertisements/' + advertisementId).then(() => {
          setTimeout(() => {
            axios.get('analytics/companies-week').then(response => {
              this.companies = response.data.results
              this.loading = false
            })
          }, 1000)
        })
      }
    },
    duplicateAdvertisement (advertisementId) {
      const r = confirm('Kopēt reklāmu?')
      if (r === true) {
        this.loading = true
        axios.post('advertisements/' + advertisementId + '/duplicate').then(() => {
          setTimeout(() => {
            axios.get('analytics/companies-week').then(response => {
              this.companies = response.data.results
              this.loading = false
            })
          }, 1000)
        })
      }
    },
    closeAdvertisement () {
      this.editedAdv = {}
      this.advertisementDialog = false
    },
    editAdvertisement (advertisementId) {
      this.loading = true
      axios.get('advertisements/' + advertisementId).then(response => {
        this.editedAdv = response.data
        this.loading = false
      }).catch(error => {
        this.alert = error.data
      })
      this.advertisementDialog = true
    },
    async saveAdvertisement () {
      this.loading = true
      axios.put('advertisements/' + this.editedAdv.id, this.editedAdv).then(() => {
        this.closeAdvertisement()
        axios.get('analytics/companies-week').then(response => {
          this.companies = response.data.results
          this.activeCompanies = response.data.active_companies
          this.loading = false
        })
      }).catch(error => {
        this.alert = error.data
      })
    },
    downloadAdvertisements (companyId) {
      axios.get('companies/' + companyId + '/export', { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = companyId
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    downloadSpreadsheet (companyId) {
      axios.get('companies/' + companyId + '/spreadsheet-export', { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = companyId
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    fetchSpreadsheets () {
      this.loading = true
      axios.get('analytics/spreadsheets').then(response => {
        this.companies2 = response.data
      }).catch(error => {
        this.alert = error.data
      })
      this.loading = false
    },
    filterMonthSearchSpreadsheet () {
      this.loading = true
      axios.post('analytics/spreadsheets', {
        month: this.month
      }).then(response => {
        this.alert = {}
        this.companies2 = response.data
      }).catch(error => {
        this.alert = error.data
      })
      this.loading = false
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style>
.v-data-table__expanded .v-data-table-header tr th {
  background: #282c96 !important;
  color: #fff !important;
}
</style>
